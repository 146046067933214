import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Us`}</h2>
    <p>{`We, The Dream Studio would love to make films of your Events, Weddings, Pre-Weddings and are honoured to grab your blissful memories and produce a beautiful movie highlights to look after.
We also love to grab your moments and freeze them with our lenses which will become memories to stay forever with you.
Modelling PhotoShoots and Filming, Product Shoots and Music Shoots for Artists are also grabbed by us.  `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      